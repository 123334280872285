import NextLink from 'next/link';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import GradeIcon from '@material-ui/icons/Grade';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ClassIcon from '@material-ui/icons/Class';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HomeIcon from '@material-ui/icons/Home';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import i18next from 'i18next';
import Notifications from '../components/Notifications';
import colours from '../styles/colours';
import useAuthedUser from '../hooks/useAuthedUser';
import LoadingSpinner from '../components/LoadingSpinner';

function quickActions(user) {
  return [
    {
      label: i18next.t('Manage Aspects'),
      icon: (
        <MenuBookIcon
          fontSize="large"
          style={{ color: colours.powder.red, height: 80, width: 80 }}
        />
      ),
      href: '/aspects',
      visible: ['ADMIN', 'SUPERADMIN'].includes(user.type),
    },
    {
      label: i18next.t('Manage Student Groups'),
      icon: (
        <PeopleOutlineIcon
          fontSize="large"
          style={{ color: colours.powder.blue, height: 80, width: 80 }}
        />
      ),
      href: '/groups',
      visible: ['ADMIN', 'SUPERADMIN'].includes(user.type),
    },
    {
      label: i18next.t('Score Systems'),
      icon: (
        <GradeIcon
          fontSize="large"
          style={{ color: colours.powder.yellow, height: 80, width: 80 }}
        />
      ),
      href: '/systems',
      visible: ['ADMIN', 'SUPERADMIN'].includes(user.type),
    },
    {
      label: i18next.t('Data Captures'),
      icon: (
        <VisibilityIcon
          fontSize="large"
          style={{ color: colours.powder.green, height: 80, width: 80 }}
        />
      ),
      href: '/captures',
      visible: ['ADMIN', 'SUPERADMIN'].includes(user.type),
    },
    {
      label: i18next.t('Change Password'),
      icon: (
        <VpnKeyIcon
          fontSize="large"
          style={{ color: colours.powder.yellow, height: 80, width: 80 }}
        />
      ),
      href: '/settings',
      visible: ['TEACHER', 'TRUST_USER'].includes(user.type),
    },
    {
      label: i18next.t('Manage Classes'),
      icon: (
        <ClassIcon
          fontSize="large"
          style={{ color: colours.powder.green, height: 80, width: 80 }}
        />
      ),
      href: '/classes',
      visible:
        ['ADMIN', 'SUPERADMIN'].includes(user.type) &&
        user.school.canManageClasses,
    },
    {
      label: i18next.t('Manage Students'),
      icon: (
        <GroupAddIcon
          fontSize="large"
          style={{ color: colours.powder.red, height: 80, width: 80 }}
        />
      ),
      href: '/studentUpload',
      visible: ['ADMIN', 'SUPERADMIN'].includes(user.type),
    },
    {
      label: i18next.t('Template View'),
      icon: (
        <AssessmentIcon
          fontSize="large"
          style={{ color: colours.powder.blue, height: 80, width: 80 }}
        />
      ),
      href: '/templates',
      visible: [
        'ADMIN',
        'TEACHER',
        'SUPERADMIN',
        'TRUST_ADMIN',
        'TRUST_USER',
      ].includes(user.type),
    },
    {
      label: i18next.t('Import Results'),
      icon: (
        <AssignmentTurnedInIcon
          fontSize="large"
          style={{ color: colours.powder.yellow, height: 80, width: 80 }}
        />
      ),
      href: '/import-results',
      visible:
        ['ADMIN', 'SUPERADMIN'].includes(user.type) &&
        (user.school.canImportResults || user.type === 'SUPERADMIN'),
    },
    {
      label: i18next.t('Aspects View'),
      icon: (
        <ListAltIcon
          fontSize="large"
          style={{ color: colours.powder.green, height: 80, width: 80 }}
        />
      ),
      href: '/aspectsView',
      visible: ['TEACHER', 'ADMIN', 'SUPERADMIN'].includes(user.type),
    },
    {
      label: i18next.t('Attendance Dashboard'),
      icon: (
        <EqualizerIcon
          fontSize="large"
          style={{ color: colours.powder.yellow, height: 80, width: 80 }}
        />
      ),
      href: '/attendance-dashboard',
      visible:
        user.school.groupcallId ||
        ['National Tests', 'SMID Primary School'].includes(user.school.name),
    },
    {
      label: i18next.t('Report Dashboard'),
      icon: (
        <AssignmentIcon
          fontSize="large"
          style={{ color: colours.powder.red, height: 80, width: 80 }}
        />
      ),
      href: '/report-dashboard',
      visible: ['SUPERADMIN'].includes(user.type),
    },
  ];
}

function ActionCard({ label, icon, href }) {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <Card variant="outlined" style={{ height: '100%', cursor: 'pointer' }}>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 25,
            userSelect: 'none',
          }}
        >
          {icon}
          <Typography
            component="h2"
            variant="h6"
            style={{ fontWeight: 800, marginTop: 10, textAlign: 'center' }}
          >
            {label}
          </Typography>
        </CardContent>
      </Card>
    </NextLink>
  );
}

export default function HomePage() {
  const user = useAuthedUser();

  if (user.isLoading) return <LoadingSpinner />;

  if (user.isError) return <p>Error: {user.error.message}</p>;

  return (
    <div>
      <main>
        <Typography>
          Welcome to {user.data.school.name} <strong>{user.data.email}</strong>
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <HomeIcon fontSize="large" />
          <Typography
            component="h2"
            variant="h4"
            style={{ fontWeight: 900, marginLeft: 10 }}
          >
            Dashboard
          </Typography>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        <Grid container spacing={2}>
          {quickActions(user.data).map(
            ({ label, icon, href, visible }) =>
              visible && (
                <Grid item xs={6} sm={4} md={3} key={label}>
                  <ActionCard label={label} icon={icon} href={href} />
                </Grid>
              )
          )}
        </Grid>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <NotificationsIcon fontSize="large" />
          <Typography
            component="h2"
            variant="h4"
            style={{ fontWeight: 900, marginLeft: 10 }}
          >
            Your Notifications
          </Typography>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        <Notifications />
      </main>
    </div>
  );
}
